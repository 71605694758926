/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Button, Text, YouTube, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-d7xh77 --style3 --full" anim={null} name={"uvod"} animS={"5"} fullscreen={true} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/cbe31539e05b4c8ba6403f2a069c0544_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/cbe31539e05b4c8ba6403f2a069c0544_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/cbe31539e05b4c8ba6403f2a069c0544_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/cbe31539e05b4c8ba6403f2a069c0544_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/cbe31539e05b4c8ba6403f2a069c0544_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/cbe31539e05b4c8ba6403f2a069c0544_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/cbe31539e05b4c8ba6403f2a069c0544_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/cbe31539e05b4c8ba6403f2a069c0544_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1400}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"} style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--30" content={"<span style=\"color: rgb(255, 255, 255);\">Young Music Space</span><br>"}>
              </Subtitle>

              <Title className="title-box ff--2 fs--154 w--900 ls--002 lh--1 mt--10" style={{"maxWidth":"","paddingBottom":0}} content={"<span style=\"color: white;\">Nu Portal</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5 fs--24 mt--16" content={"Nebeská Párty"} url={"https://www.youtube.com/watch?v=Zw6MTT1QvXA"} href={"https://www.youtube.com/watch?v=Zw6MTT1QvXA"}>
              </Button>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5 fs--24 mt--16" content={"Highest Message"} url={""} href={""}>
              </Button>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5 fs--24 mt--16" content={"Kamarát"} url={""} href={""}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--60" name={"o-nas"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2 flex--center" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="pt--10" style={{"maxWidth":550}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(227, 20, 20);\">Nu Portal</span>"}>
              </Title>

              <Title className="title-box fs--36 w--500 ls--004" style={{"maxWidth":583,"paddingRight":0}} content={"hudobná platforma pre mladé talenty"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--15);\">Nu Portal je partia mladých ľudí, čo chcú robiť a robia hudbu pre mladú generáciu. Piesne o Bohu, o živote, o tom, čo prežívame. <br>Ak vieš spievať, alebo máš dobré pesničky a chcel by si sa k nám pridať, len daj o sebe vedieť, radi ťa spoznáme! <br>Nu Portal je práve to miesto, kde môže tvoj hlas a tvoja pieseň zaznieť! :)&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pt--10" style={{"maxWidth":800}}>
              
              <YouTube embedId={"Zw6MTT1QvXA"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"g0sx1rdennh"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40248/8de80deb96244919b003edd567252bc3_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40248/8de80deb96244919b003edd567252bc3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40248/8de80deb96244919b003edd567252bc3_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Sarah Tóthová"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-blend--15);\">Milujem hudbu, ale ešte väčšmi milujem Pána Boha. Najradšej spievam Jemu na chválu, lebo vďaka Nemu tu môžem aj ja dnes byť. Je to obrovská radosť v srdci, keď Ho môžem chváliť a ďakovať Mu za všetko, čo pre mňa urobil, robí a bude robiť.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40248/caf5a7d6f1f74b35996fc99fa1acbfee_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40248/caf5a7d6f1f74b35996fc99fa1acbfee_s=350x_.png 350w, https://cdn.swbpg.com/t/40248/caf5a7d6f1f74b35996fc99fa1acbfee_s=660x_.png 660w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Monika Bačíková"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-blend--15);\">Spev je pre mňa naplnením. Už od malička sa mu venujem a budujem dar ktorý mi Pán dal.&nbsp; <br>Spievam chvály, piesne o Bohu, na jeho oslavu a práve v nich sa môžem so živým Bohom stretnúť. <br>Je to niečo veľmi špeciálne, intímne a nádherné.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40248/7bb714e9016c4bcdaac10b9d48bed2b8_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40248/7bb714e9016c4bcdaac10b9d48bed2b8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40248/7bb714e9016c4bcdaac10b9d48bed2b8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40248/7bb714e9016c4bcdaac10b9d48bed2b8_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Saskia Stupková"}>
              </Title>

              <Text className="text-box text-box--left" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-blend--15);\">Chváľte Pána v jeho svätyni - chváľte ho bicími&nbsp; a tancom, chváľte ho gitarou a basou. Chváľte ho jasavými synťákmi. Všetko, čo dýcha, nech chváli Hospodina! Haleluja!<br>Žalm 150 (adaptácia pre súčasné nástroje)</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"o-nas-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--4 pb--10" anim={"2"} animS={"5"} columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40248/fc63fa83f1c7423c86722d31fd7e7503_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/40248/fc63fa83f1c7423c86722d31fd7e7503_s=350x_.jpg 350w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--left fs--36" content={"Bruno Kaiser"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":650}} content={"<span style=\"color: var(--color-blend--15);\">Verím, že vďaka spevu a hudbe dokážem úprimne vyjadriť Bohu svoju lásku a vďaku. <br>Tiež je to spôsob, ako si môžem vyčistiť a nastaviť hlavu, keď veci nie sú tak jasné.<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40248/b29dac39e2814c10b9c961c0efefd192_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/40248/b29dac39e2814c10b9c961c0efefd192_s=350x_.jpg 350w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--left fs--36" content={"Daren Ludvig"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":650}} content={"<span style=\"color: var(--color-blend--15);\">Už od malička som miloval hudbu a&nbsp; všetko s ňou spojené a som rád, že môžem cez ňu vyjadriť talent, ktorý mi Boh dal. Žalm 144 hovorí: \"Spievať chcem Hospodinovi, kým žijem.\" a toto je práve aj mojou túžbou.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40248/ac0802e84ef84300b5c48da8142428b8_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/40248/ac0802e84ef84300b5c48da8142428b8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40248/ac0802e84ef84300b5c48da8142428b8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40248/ac0802e84ef84300b5c48da8142428b8_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--left fs--36" content={"Adam Hudec"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":650}} content={"<span style=\"color: var(--color-blend--15);\">Náš dvorný&nbsp; aranžér a zvukový majster. Tiež multiinštrumentalista obsluhujúci klávesy a basu.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40248/498805b58570401cb55959becda0b6a7_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/40248/498805b58570401cb55959becda0b6a7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40248/498805b58570401cb55959becda0b6a7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40248/498805b58570401cb55959becda0b6a7_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--left fs--36" content={"Erik Stupka"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":650}} content={"<span style=\"color: rgb(47, 53, 65);\">Hlavný producent a zakladateľ celého projektu. <br>Tiež autor väčšiny piesní.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-7erv8x pb--60 pt--60" name={"nase-tvorba"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/f04fa2b3d3d44fc48f4e76757951b89d_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/f04fa2b3d3d44fc48f4e76757951b89d_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/f04fa2b3d3d44fc48f4e76757951b89d_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/f04fa2b3d3d44fc48f4e76757951b89d_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/f04fa2b3d3d44fc48f4e76757951b89d_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/f04fa2b3d3d44fc48f4e76757951b89d_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/f04fa2b3d3d44fc48f4e76757951b89d_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40248/f04fa2b3d3d44fc48f4e76757951b89d_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 flex--center" anim={"2"} animS={"5"} style={{"maxWidth":500}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Vypočujte si naše songy</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--shape5 fs--22" content={"Nebeská Párty"} url={"https://www.youtube.com/watch?v=Zw6MTT1QvXA"} href={"https://www.youtube.com/watch?v=Zw6MTT1QvXA"}>
              </Button>

              <Button className="btn-box btn-box--shape5 fs--22" content={"Highest Message"} url={""} href={""}>
              </Button>

              <Button className="btn-box btn-box--shape5 fs--22" content={"Kamarát"} url={""} href={""}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" anim={""} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="pt--10" style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box w--900" content={"<span style=\"color: rgb(227, 18, 18);\">Nu Portal</span><br>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">Radi odpovieme na tvoje otázky.<br>Napíš, četuj, zdieľaj, sleduj - budeme radi, keď sa nám ozveš!</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right pt--10" style={{"maxWidth":800}}>
              
              <Subtitle className="subtitle-box w--900" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">+421 903 744 388<br>nuportal007@gmail.com<br>Vytvorené cez&nbsp;<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}